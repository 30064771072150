
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { CareGiver } from "@/models/care_givers";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import Search from "@/components/shared/Search.vue"

  export default defineComponent({
    components: {
      Search
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.care_giver_instances.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'include': 'care_giver'
        }
        await root.$store.dispatch('care_giver_instances/load', queryParams)
        return root.$store.state.care_giver_instances.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.care_giver.attributes.firstName",
          title: "Vorname",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.care_giver.attributes.lastName",
          title: "Nachname",
          sortable: true,
          options: {
            hrefFunction: (data: any) => resourceUrl(data.item)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.gs",
          title: "DK",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.exp",
          title: "Erfahrung",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.expGer",
          title: "Erfahrung (dt.)",
          sortable: true,
        },
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   type: "Def",
        //   key: "householdFamilyNames",
        //   title: "Vertrag mit",
        //   sortable: true,
        //   options: {
        //     contentFunction: (data:any) => {
        //       const crs = data.item.attributes.household.attributes.care_receivers
        //       const familyName = crs.map(cr => cr.attributes.address.attributes.lastName).join(', ').trim()
        //       if (crs.length == 0) {return 'ERROR! Keine Patienten'}
        //       return familyName !== '' ? familyName : 'Keiner vorhanden'
        //     },
        //     hrefFunction: (data: any) => `/care_givers/${data.item.id}`
        //   }
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.agency.attributes.name",
        //   title: "Agentur",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.clientSigned",
        //   title: "Sig. Kd.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.agencySigned",
        //   title: "Sig. Ag.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.active",
        //   title: "Aktiv",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.archived",
        //   title: "Archiviert",
        //   sortable: true,
        // },
      ])

      const searchColumns = [
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.care_giver.attributes.firstName",
          title: "Vorname",
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.care_giver.attributes.lastName",
          title: "Nachname",
          options: {
            hrefFunction: (data: any) => resourceUrl(data.item)
          }
        },
      ]

      const titleActions = []

      return {
        getItems,
        totalItems,
        columns,
        searchColumns,
        titleActions
      }
    }
  })
